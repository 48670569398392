export default () => {
  let casesListItems = document
    .querySelector('[cases="cases-list"]')
    .querySelectorAll('.w-dyn-item');
  const testimonialListItems = document
    .querySelector('[cases="testimonial-list"]')
    .querySelectorAll('.w-dyn-item');

  const TempArr = [];
  for (let x = casesListItems.length; x--; ) {
    TempArr.push(casesListItems[x]);
  }
  casesListItems = TempArr;

  let testimonialListIndex = 0;

  casesListItems.forEach((caseItem, i) => {
    const calculatedIndex = i + 1;

    if (calculatedIndex % 4 === 0 && i !== 0) {
      caseItem.before(testimonialListItems[testimonialListIndex]);

      testimonialListIndex += 1;
    }
  });
};
