import InfiniteMarquee from 'infinite-marquee';

export default () => {
  const logoWrapper = document.querySelectorAll('.testimonial_logo-wrapper');

  logoWrapper.forEach((wrapper) => {
    const aspectRatioValue = wrapper.getAttribute('aspect-ratio');

    const compStyles = window.getComputedStyle(wrapper);
    const wrapperHeightProperty = compStyles.getPropertyValue('height');
    const wrapperHeightFloat = parseFloat(wrapperHeightProperty.replace('px', ''));

    const aspectRatioArray = aspectRatioValue.split('/');
    const aspectRatioParamOne = parseFloat(aspectRatioArray[0]);
    const aspectRatioParamTwo = parseFloat(aspectRatioArray[1] ? aspectRatioArray[1] : 1);

    const wrapperCalculatedWidth = (wrapperHeightFloat * aspectRatioParamOne) / aspectRatioParamTwo;

    wrapper.style.width = `${wrapperCalculatedWidth}px`; //eslint-disable-line
  });

  const marqueeLogos = new InfiniteMarquee({
    el: document.querySelector('.marquee'),
    direction: 'left',
    duration: 25,
    css: false,
  });

  marqueeLogos; //eslint-disable-line
};
