import Navigo from 'navigo';

import homeRoute from './Routes/home';
import careerRoute from './Routes/career';
import casesOverviewRoute from './Routes/casesOverview';
import contactRoute from './Routes/contact';
import blogRoute from './Routes/blog';
import landingpageRoute from './Routes/landingpage';

const router = new Navigo('/');

router.on('/', () => {
  homeRoute();
});

router.on('/karriere', () => {
  careerRoute();
});

router.on('/case-study', () => {
  casesOverviewRoute();
});

router.on('/kontakt', () => {
  contactRoute();
});

router.on('/blog', () => {
  blogRoute();
});

router.on('/landingpage', () => {
  landingpageRoute();
});

router.on(/landing-page\/(.*)/, () => {
  landingpageRoute();
});

router.notFound(() => true);

router.resolve();
