import InfiniteMarquee from 'infinite-marquee';
import imagesLoaded from 'imagesloaded';

export default () => {
  const posts = document.querySelectorAll('.career-team_image');

  function startMarquee() {
    setTimeout(() => {
      const marqueeCareer = new InfiniteMarquee({
        el: document.querySelector('.marquee'),
        direction: 'left',
        duration: 50,
        css: false,
      });

      marqueeCareer; //eslint-disable-line
    }, 1000);
  }

  imagesLoaded(posts, startMarquee);
};
