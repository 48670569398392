import InfiniteMarquee from 'infinite-marquee';

/* eslint-disable no-restricted-syntax */

export default () => {
  // Select the target node on which to observe DOM mutations
  const teamList = document.querySelector('[team-marquee="list"]');

  // Define the callback function to be executed when mutations are observed
  const callback = function (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length) {
        // Check if the added node is the specific element we are interested in
        const isCTAAdded = Array.from(mutation.addedNodes).some(
          (node) => node.getAttribute && node.getAttribute('team-marquee') === 'cta-item'
        );
        if (isCTAAdded) {
          // Stop observing to avoid reinitialization
          observer.disconnect();

          // Initialize your InfiniteMarquee here
          const marqueeTeam = new InfiniteMarquee({
            el: document.querySelector('.marquee-team'),
            direction: 'left',
            duration: 75,
            css: false,
          });
        }
      }
    }
  };

  // Create an instance of the MutationObserver
  const observer = new MutationObserver(callback);

  // Configuration for the observer (observe child list mutations)
  const config = { childList: true };

  // Start observing the target node for configured mutations
  observer.observe(teamList, config);

  // Append the teamCTA to teamList, which should trigger the observer
  const teamCTA = document.querySelector('[team-marquee="cta-item"]');

  teamList.appendChild(teamCTA);
};
