export default () => {
  window.fsAttributes = window.fsAttributes || [];
  window.fsAttributes.push([
    'cmssort',
    () => {
      document.querySelector('[fs-cmssort-element="trigger"]').click();
      setTimeout(() => {
        document.querySelector('.blog_component').style.opacity = 1;
        document.querySelector('.blog_component').style.pointerEvents = 'auto';
      }, 500);
    },
  ]);
};
